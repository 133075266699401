// src/Gradient.js
const gradients = [
    "linear-gradient(45deg, #f0f0f0, #f8f8f8, #f0f0f0)",
    "linear-gradient(45deg, #e8f6f6, #f0fafa, #e8f6f6)",
    "linear-gradient(45deg, #f6e8f6, #f0f0fa, #f6e8f6)",
    "linear-gradient(45deg, #f6f6e8, #f8f8dc, #f6f6e8)",
    "linear-gradient(45deg, #e8f6e8, #f0fafa, #e8f6e8)",
    "linear-gradient(45deg, #f6e8e8, #fae8e8, #f6e8e8)",
    "linear-gradient(45deg, #e8e8f6, #f0f0fa, #e8e8f6)",
    "linear-gradient(45deg, #f6f0e8, #f8f4dc, #f6f0e8)",
    "linear-gradient(45deg, #e8e8e8, #f0f0f0, #e8e8e8)",
    "linear-gradient(45deg, #f0f6e8, #f4f8dc, #f0f6e8)",
    "linear-gradient(45deg, #f2f2f2, #e4e4e4, #f2f2f2)",
    "linear-gradient(45deg, #ebf5f5, #dfecec, #ebf5f5)",
    "linear-gradient(45deg, #e3f2f2, #d7ebeb, #e3f2f2)",
    "linear-gradient(45deg, #f2e3e3, #ecd7d7, #f2e3e3)",
    "linear-gradient(45deg, #e3e3f2, #d7d7ec, #e3e3f2)",
    "linear-gradient(45deg, #f2f2e3, #e7e7d7, #f2f2e3)",
    "linear-gradient(45deg, #e3f2e3, #d7ecd7, #e3f2e3)",
    "linear-gradient(45deg, #f2e3f2, #ecd7ec, #f2e3f2)",
    "linear-gradient(45deg, #f2f2f2, #e8e8e8, #f2f2f2)",
    "linear-gradient(45deg, #e8f2f2, #dce8e8, #e8f2f2)"
  ];
  
  export const getGradient = (index) => gradients[index % gradients.length];
  