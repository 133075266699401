// src/App.js
import React, { useState, useEffect, useRef } from 'react';
import Card from './Card';
import './App.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; // If using npm installation

const App = () => {
  const [flashcards, setFlashcards] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const cardRefs = useRef([]);
  const headerRef = useRef(null);

  useEffect(() => {
    fetch('/flashcards.json')
      .then(response => response.json())
      .then(data => setFlashcards(data));
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setShowScrollToTop(window.scrollY > 300);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleSearch = (event) => {
    const term = event.target.value;
    setSearchTerm(term);

    if (term.trim() === '') {
      setSearchResults([]);
      return;
    }

    const results = flashcards.filter(flashcard => 
      flashcard.topic.toLowerCase().includes(term.toLowerCase())
    );
    setSearchResults(results);
  };

  const handleResultClick = (index) => {
    const headerHeight = headerRef.current ? headerRef.current.offsetHeight : 0;
    const elementTop = cardRefs.current[index].offsetTop;
    const offsetPosition = elementTop - headerHeight - 10; // 10px extra padding

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });

    setSearchTerm('');
    setSearchResults([]);
    setIsSearchOpen(false); // Close search bar after selection
  };

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div className="app">
      <header className="app-header" ref={headerRef}>
        <h1>Arun Akchutha</h1>
        <div className={`search-container ${isSearchOpen ? 'open' : ''}`}>
          <i className="fas fa-search search-icon" onClick={() => setIsSearchOpen(!isSearchOpen)}></i>
          {isSearchOpen && (
            <div className="search-box">
              <input
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={handleSearch}
                className="search-input"
              />
              {searchResults.length > 0 && (
                <ul className="search-results">
                  {searchResults.map((result, idx) => (
                    <li
                      key={idx}
                      onClick={() => handleResultClick(flashcards.findIndex(fc => fc.topic === result.topic))}
                      className="search-result-item"
                    >
                      {result.topic}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )}
        </div>
      </header>
      <div className="card-container">
        {flashcards.map((flashcard, index) => (
          <div id={`card-${index}`} key={index} ref={el => cardRefs.current[index] = el}>
            <Card
              title={flashcard.questions.join('\n')}
              content={flashcard.answers.join('\n')}
              index={index}
              topic={flashcard.topic}
            />
          </div>
        ))}
      </div>
      {showScrollToTop && (
        <div className="scroll-to-top" onClick={handleScrollToTop}>
          <i className="fas fa-chevron-up"></i>
        </div>
      )}
    </div>
  );
};

export default App;
