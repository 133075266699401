// src/scrollProgress.js
export function updateProgressBar() {
    const progressBar = document.getElementById('progress-bar');
    const scrollTotal = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    const scrollCurrent = document.documentElement.scrollTop;
  
    const scrollPercentage = (scrollCurrent / scrollTotal) * 100;
    progressBar.style.width = `${scrollPercentage}%`;
  }
  
  window.addEventListener('scroll', updateProgressBar);
  