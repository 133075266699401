// src/Card.js
import React, { useState } from 'react';
import './Card.css';
import { getGradient } from './Gradient';

const Card = ({ title, content, index, topic }) => {
  const [flipped, setFlipped] = useState(false);

  const handleFlip = () => {
    setFlipped(!flipped);
  };

  const handleMouseLeave = () => {
    if (flipped) {
      setFlipped(false);
    }
  };

  return (
    <div className="card" style={{ backgroundImage: getGradient(index) }}>
      <div className="card-banner">
        <h3>{topic}</h3>
      </div>
      <div
        className="card-content-wrapper"
        onClick={handleFlip}
        onMouseLeave={handleMouseLeave}
      >
        <div className={`card-content front ${flipped ? 'fade-out' : 'fade-in'}`}>
          {title.split('\n').map((line, index) => (
            <p key={index}>{line}</p>
          ))}
        </div>
        <div className={`card-content back ${flipped ? 'fade-in' : 'fade-out'}`}>
          {content.split('\n').map((line, index) => (
            <p key={index}>{line}</p>
          ))}
        </div>
      </div>
      <div className="card-footer"></div>
    </div>
  );
};

export default Card;
